<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Jobs Rescheduled
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="homes"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "jobs-rescheduled",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

     approve(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=approveNewHome&id='+id)
      .then(window.location.reload()).catch(console.log)
  } ,

  recuse(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=refuseNewHome&id='+id)
      .then(window.location.reload()).catch(console.log)
  }
  },
  data() {
    return {
      homes: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Independent Contractor", value: "worker" },
        { text: "Client", value: "client" },
        { text: "Date Schedule", value: "date" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readRescheduled")
      .then(res => res.json())
      .then(data => {
        this.homes = data.rescheduled;
        //console.log('RES: '+ JSON.stringify(data.independent));
      })
      .catch(console.log);
  }
};
</script>
